//@flow
import * as React from 'react'
import styled from '@emotion/styled'

import { rhythm } from '../../utils/typography'
import theme from '../../utils/theme'

import Fonts from '../layoutHelpers/Fonts'

const ModalBox = styled.div`
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
`
const Container = styled.div`
  margin-top: ${rhythm(6)};
  margin-left: ${rhythm(1)};
  margin-right: ${rhythm(1)};
  display: flex;
  flex: 1;
  flex-direction: ${p => (p.reverse ? 'row-reverse' : 'row')};
  max-width: 440px;
  height: 300px;
  background-color: ${theme.colors.invertedFont};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  > div {
    width: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
`

type Props = {
  title: ?React.Node,
  description: ?React.Node,
  imageUrl: string,
  renderButton: ?React.Node,
  show: boolean,
}

class Modal extends React.PureComponent<Props> {
  render() {
    const { title, description, renderButton, show } = this.props

    if (!show) {
      return null
    }

    return (
      <ModalBox>
        <Container>
          {/* <ImageContainer imageUrl={imageUrl} />*/}
          <Content>
            {title && <Fonts.Title>{title}</Fonts.Title>}
            {description && <Fonts.Paragraph>{description}</Fonts.Paragraph>}
            {renderButton && renderButton}
          </Content>
        </Container>
      </ModalBox>
    )
  }
}

export default Modal
