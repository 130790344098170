//@flow
import * as React from 'react'
import styled from '@emotion/styled'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import Theme from '../../utils/theme'
import Layout from '../../components/Layout'
import Page from '../../components/ui/Page'
import SplitContainer from '../../components/ui/SplitContainer'
import Modal from '../../components/ui/Modal'
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import Helmet from 'react-helmet'

import modalsuccessimage from './images/adam-whitlock-unsplash.jpg'
import modalfailimage from './images/neonbrand-unsplash.jpg'
import backDoor from './images/brick-door-crop.png'

const { Title, Paragraph: P } = SplitContainer

const A = styled.a`
  text-decoration: none;
  color: ${Theme.colors.primaryFont};
  font-weight: 500;
  font-size: 16px;
  padding: 0px 0px 0px 0px;
  display: inline-block
  cursor: pointer;
  &:hover {
    color: ${Theme.colors.selection};
  }
`

function flattenObject(obj, inRet, inPrefix) {
  const ret = inRet || {}
  const prefix = inPrefix || ''
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      flattenObject(obj[key], ret, prefix === '' ? key : `${prefix}[${key}]`)
    })
  } else if (prefix !== '') {
    ret[prefix] = obj
  }

  return ret
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const schema = yup.object().shape({
  contactName: yup.string().required('Name is required'),
  contactEmail: yup.string().required('Email is required'),
  contactPhone: yup.string().required('Valid number is required'),
  description: yup.string().required('What can we help you with?'),
})

const FORM_NAME = 'contact-us-form'

// TODO: Complete with values
const initialFormValues = {
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  description: '',
}

type State = {
  showSuccess: boolean,
  showError: boolean,
}

class ContactUsPage extends React.PureComponent<*, State> {
  state = {
    showSuccess: false,
    showError: false,
  }
  handleSubmit = (values: *, { resetForm }: *) => {
    try {
      const flatten = flattenObject({
        'form-name': FORM_NAME,
        ...values,
      })

      return fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode(flatten),
      })
        .then(() => {
          this.setState({ showSuccess: true })
          resetForm(initialFormValues)
        })
        .catch(() => this.setState({ showError: true }))
    } catch (err) {
      console.error(err)
    }
  }
  render() {
    const { showSuccess, showError } = this.state
    return (
      <Layout>
        <Page>
          <Helmet title={`Contact Us`} />
          <SplitContainer imageUrl={backDoor}>
            <Title>The backdoor access to Insider information</Title>

            <P>
              How can we help you? Are you a visitor with questions about Park
              City or an event or are you a venue that wants to get the word out
              to other Insiders about your festivities?
            </P>
            <P>
              Drop us a line, and we’ll get back to you as soon as we can.
              Seriously, ask us anything or say hello! We want to hear from you.
            </P>
            <P>
              <A href="tel:14355570094">1-435-557-0094 </A> <br />
              <A href="mailto:jay@parkcityinsider.com">
                jay@parkcityinsider.com{' '}
              </A>
            </P>
            <Formik
              initialValues={initialFormValues}
              validationSchema={schema}
              validateOnBlur={false}
              onSubmit={this.handleSubmit}
              render={({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    name={FORM_NAME}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value={FORM_NAME} />

                    <Input.FormikWrapper
                      label="Your name"
                      hasError={t => t.contactName}
                      form={{ errors, touched }}
                      renderField={
                        <Input
                          type="text"
                          name="contactName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactName}
                        />
                      }
                    />

                    <Input.FormikWrapper
                      label="Email"
                      hasError={t => t.contactEmail}
                      form={{ errors, touched }}
                      renderField={
                        <Input
                          type="text"
                          name="contactEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactEmail}
                        />
                      }
                    />
                    <Input.FormikWrapper
                      label="Phone Number"
                      hasError={t => t.contactPhone}
                      form={{ errors, touched }}
                      renderField={
                        <Input
                          type="text"
                          name="contactPhone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPhone}
                        />
                      }
                    />
                    <Input.FormikWrapper
                      label="How can we help you?"
                      hasError={t => t.description}
                      form={{ errors, touched }}
                    >
                      <Field
                        name={'description'}
                        component={Input.FormikTextArea}
                      />
                    </Input.FormikWrapper>
                    <Button.Submit>Submit</Button.Submit>
                  </form>
                )
              }}
            />
          </SplitContainer>
          <Modal
            show={showSuccess}
            title={'Success!'}
            description={
              'We have received your submissision. Expect to hear from us over the  next few days. '
            }
            renderButton={
              <Button
                type="button"
                onClick={() => {
                  this.setState({ showSuccess: false })
                }}
              >
                Close
              </Button>
            }
            imageUrl={modalsuccessimage}
          />
          <Modal
            show={showError}
            title={'Oops!'}
            description={
              'Something went wrong with the submission. Please wait a few minutes and then try again.'
            }
            renderButton={
              <Button
                type="button"
                onClick={() => {
                  this.setState({ showError: false })
                }}
              >
                Close
              </Button>
            }
            imageUrl={modalfailimage}
          />
        </Page>
      </Layout>
    )
  }
}

export default ContactUsPage
